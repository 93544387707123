import React, { useEffect, useState } from 'react'
import styles from '../Profile.module.scss'
import FormInput from '../../../assets/common/FormInput/FormInput'
import FileLabel from '../../../components/FileLabel/FileLabel'
import CustomInput from '../../../components/CustomInput/CustomInput'
import CustomSelect from '../../../components/CustomSelect/CustomSelect'
import { BACKEND_DOMAIN, SPORT_GRADES } from '../../../utils/const'
import Modal from 'react-responsive-modal'
import { ButtonRed } from '../../../assets/common/ButtonRed/ButtonRed'
import { IoIosClose, IoIosCreate, IoMdAdd, IoMdCreate } from 'react-icons/io'
import { useFormik } from 'formik'
import { createSportActivity, deleteSportActivity, getActivityTypes, updateSportActivity } from '../../../actions/yunarmiyans'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { getYearsArr } from '../../../utils/yunarmyStat'
import cn from 'classnames'

const imageReg = /\.(jpg|jpeg|png|gif|bmp)$/i

const INITIAL_ACTIVITY_DATA = {
  name: '',
  result: '',
  year: moment().year(),
  array_agg: []
}

const SportsActivities = ({ data, profileId, editMode }) => {
  
  const dispatch = useDispatch()
  
  const [modal, setModal] = useState(null)  

  const openModal = (data) => () => setModal(data)

  const deleteActivityHandler = (activId) => () => {
    dispatch(deleteSportActivity(activId))
  }

  const renderFile = file => {
    const fileLink = `${BACKEND_DOMAIN || ''}${file.file_path}`
    const fileName = file.file_path.split('/').at(-1)
    return (
      <FileLabel
        imageMode={imageReg.test(file.file_path)}
        fileLink={fileLink}
        fileName={fileName}
      />
    )
  }
  
  const renderActivity = (activity) => (
    <div className={styles.medicineBlock}>
      <div className={styles.dataRow}>
        <div className={styles.rowWrap}>
          <div>
            <FormInput
              width="300px"
              title="Название"
              editMode={false}
              value={activity.name}
            />
          </div>
        </div>
        <div className={styles.rowWrap}>
          <div>
            <FormInput
              width="300px"
              title="Достижение"
              editMode={false}
              value={activity.result}
            />
          </div>
        </div>
        <div className={styles.gtoWrapper}>
          {activity.array_agg?.map(renderFile)}
        </div>
        
        {editMode &&
          <div className={styles.iconsBlock}>
            <IoMdCreate onClick={openModal(activity)} color='#6a1919' title='Редактировать'/>
            <IoIosClose onClick={deleteActivityHandler(activity.id)} size={26} title='Удалить' color='#6a1919'/>
          </div>
        }
      </div>
    </div>
  )

  return (
    <>
      <div className={styles.medicine}>
        <div className={styles.title}>Спортивные достижения</div>
        {data?.map(renderActivity)}
        <ActivityModal
          open={!!modal}
          onClose={() => setModal(null)}
          data={modal}
          addMode={!modal?.id}
          profileId={profileId}
        />
      </div>
        {editMode &&
          <div onClick={openModal(INITIAL_ACTIVITY_DATA)} className={cn(styles.fileModalButton, styles.smallFileModalButton)}>
            <IoMdAdd size={30} color='#ced4da'/>
          </div>
        }
    </>
  )
}

export default SportsActivities





const ActivityModal = ({ open, onClose, data, addMode, profileId }) => {

  const modalTitle = addMode ? 'Добавление спортивного достижения' : 'Редактирование спортивного достижения'

  const dispatch = useDispatch()

  const addFiles = (e) => {
    const files = Array.from(e.target.files)
    setFieldValue('array_agg', [...values.array_agg, ...files])
  }

  const openFilesLoad = () => {
    const input = document.getElementById('inputFiles')
    input.click()
  }

  const deleteFileHandler = (index) => () => {
    const newFilesList = values.array_agg.filter((it, i) => i !== index)
    setFieldValue('array_agg', newFilesList)
  }

  const renderFile = (file, index) => {
    const { file_path, name } = file
    const fileLink = file_path ? `${BACKEND_DOMAIN || ''}${file_path}` : URL.createObjectURL(file)
    const fileName = file_path ? file_path.split('/').at(-1) : name
    const imageMode = file_path ? imageReg.test(file_path) : file.type.includes('image') 
    return (
      <FileLabel
        imageMode={imageMode}
        fileLink={fileLink}
        fileName={fileName}
        onDelete={deleteFileHandler(index)}
      />
    )
  }

  const addActivityHandler = async (values) => {
    const payload = {...values, profile_id: profileId}
    await dispatch(createSportActivity(payload))
    onClose()
  }
  
  const editActivityHandler = async ({id, ...values}) => {
    await dispatch(updateSportActivity(id, values, data))
    onClose()
  }

  const {
    values,
    handleChange, 
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: data || INITIAL_ACTIVITY_DATA,
    enableReinitialize: true,
    onSubmit: addMode ? addActivityHandler : editActivityHandler
  })


  return (
    <Modal
      open={open}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      classNames={{modal: styles.modal}}
    >

      <h3>{modalTitle}</h3>

      <CustomInput
        name='name'
        title="Название"
        value={values.name}
        onChange={handleChange}
      />

      <CustomSelect
        name='year'
        title="Год получения"
        selectedValue={values.year}
        options={getYearsArr(2016).map(el => ({name:el,value:el}))}
        width='120px'
        onChange={handleChange}
      />

      <CustomSelect
        name='result'
        title="Достижение"
        selectedValue={values.result}
        options={SPORT_GRADES.map(grade => ({name: grade, value: grade}))}
        onChange={handleChange}
      />

      <div className={styles.filesBlock}>
        <p>Файлы:</p>
        <ul>
          {values.array_agg?.map(renderFile)}
          <li onClick={openFilesLoad} className={styles.fileModalButton}>
            <input type='file' onChange={addFiles} id='inputFiles' accept='image/*, application/pdf' multiple hidden />
            <IoMdAdd size={30} color='#ced4da'/>
          </li>
        </ul>
      </div>

      <div className={styles.buttonsBlock}>
        <ButtonRed onClick={handleSubmit}>Сохранить</ButtonRed>
        <ButtonRed onClick={onClose}>Отмена</ButtonRed>
      </div>

    </Modal>
  )
}