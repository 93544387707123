import * as types from "../actions/types";
import { AUTH_ERROR_MESSAGES } from "../utils/const";

const initialState = {
  url: "/token/sso",
  user: null,
  userImage: null,
  dashboard: null,
  regions: null,
  userRegions: null,
  accessRights: null,
  itemsOnPage: 10,
  dashboard_loading: false,
  loading: false,
  ERROR: null
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case types.IS_BASIC_LOGIN_ALLOWED:
      return {
        ...state,
        allowBasicLogin: action.allowBasicLogin,
        error: action.allowBasicLogin ? null : AUTH_ERROR_MESSAGES[401],
      };
    case types.AUTH_COMPLETED:
      return {
        ...state,
        ...action.user,
        error: null,
        errorNeedAuth: null,
      };
    case types.AUTH_FAILED:
      return {
        ...initialState,
        error: action.error,
        url: state.url,
      };
    case types.SIGNED_OUT:
      return {
        ...initialState,
        url: state.url,
      };
    case types.SSO_IS_NOT_IMPLEMENTED:
      return {
        ...state,
        url: "/token",
        error: null,
        errorNeedAuth: null,
      };
    case types.AUTH_ERROR_MODAL_CLOSED:
      return {
        ...state,
        error: null,
        errorNeedAuth: null,
      };
    case types.FETCHED_USER:
      return {
        ...state,
        user: action.user,
      };
    case types.SET_USER_INFO:
      return {
        ...state,
        user: action.payload,
      };
    case types.SET_USER_REGIONS_AUTH:
      return {
        ...state,
        userRegions: action.payload,
      };
    case types.EDIT_USER_PHOTO:
      return {
        ...state,
        userImage: action.payload,
      };
    case types.SET_USER_PHOTO_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case types.SET_DASHBOARD_INFO:
      return {
        ...state,
        dashboard: action.payload,
      };
    case types.SET_REGION_LIST_AUTH:
      return {
        ...state,
        regions: action.payload,
      };
    case types.SET_USER_ACCESS_RIGHTS:
      return {
        ...state,
        accessRights: action.payload,
      };
    case types.SET_DASHBOARD_INFO_LOADING:
      return {
        ...state,
        dashboard_loading: action.payload,
      };
    case types.SET_ITEMS_ON_PAGE: {
      return {
        ...state,
        itemsOnPage: action.payload,
      };
    }
    case types.ERROR:
      return {
        ...state,
        ERROR: action.payload,
      };
    default:
      return state;
  }
}
