import React, { useState, useEffect } from "react";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import styles from "./ConfirmationMode.module.css";
import { IoMdAdd, IoMdRemove, IoMdRemoveCircleOutline } from "react-icons/io";
import { InputFile } from "../../../assets/common/InputFile/InputFile";
import Confirmation from "../../../assets/common/Confirmation/Confirmation";
import { BACKEND_DOMAIN, BACKEND_URL_PREFIX, directories } from "../../../utils/const";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useRef } from "react";
import FileLabel from "../../../components/FileLabel/FileLabel";
import Loader from "../../../components/Loader/Loader";



export default function ConfirmationMode({
  getRatingValue,
  year,
  sortedBy,
  valueId,
  ratingValue,
  setRatingValue,
  removeRatingValue,
  ratingValueAction,
  setConfirmationMode,
  setRatingValueAction,
  loading,
  haveEditRight,
  token,
  status
}) {

  const [plug, setPlug] = useState(null)
  const [tableMode, setTableMode] = useState(true)
  const [data, setData] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [filesList, setFilesList] = useState([]);
  const [addFilesList, setAddFilesList] = useState([]);
  const [removeFiles, setRemoveFiles] = useState([]);
  const [activeCell, setActiveCell] = useState(null)
  const [localLoading, setLocalLoading] = useState(false)

  const activeField = useRef(null)


  useEffect(() => {
    getRatingValue(valueId);
    return () => {
      removeRatingValue()
    }
  }, []);

  useEffect(() => {
    if (ratingValue) {
      setData(ratingValue);
      setFilesList(ratingValue.files);
      getPlug(ratingValue.rating_id)
    }
  }, [ratingValue]);

  useEffect(() => {
    if (plug) {
      const newRatingValue = JSON.parse(JSON.stringify(ratingValue))
      if (ratingValue.dynamic_fields?.fields?.length) {
        if (!Array.isArray(newRatingValue.dynamic_fields.fields[0])) {
          newRatingValue.dynamic_fields.fields = [newRatingValue.dynamic_fields.fields]
        }
        const newRows = newRatingValue.dynamic_fields.fields.map(row => {
          const data = plug.map(item => {
            const object = row.find(el => el.name === item.name)
            const value = object ? object.value : ""
            return {...item, value: value}
          })
          return data
        })
        newRatingValue.dynamic_fields.fields = newRows
      } else {
        newRatingValue.dynamic_fields = { fields: [plug] }
      }
      newRatingValue.dynamic_fields.table = tableMode
      setData(newRatingValue)
    }
  }, [plug])
  
  useEffect(() => {
    if (ratingValueAction) {
      sendRatingValueRequest()
    }
  }, [ratingValueAction]);

  useEffect(() => {
    document.body.addEventListener('click', handleOutsideClick);
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    }
  }, []);



  const sendRatingValueRequest = async () => {
    const _data = {
      is_confirmed: data.is_confirmed,
      confirmation_message: data.confirmation_message,
      dynamic_fields: data.dynamic_fields
    };
    await setRatingValue(valueId, year, sortedBy, _data, addFilesList, removeFiles);
    setConfirmationMode(null);
    setRatingValueAction(false);
  }
  

  const getPlug = async (ratingId) => {
    setLocalLoading(true)
    const data = await fetch(`${BACKEND_URL_PREFIX}/rating_parameters/${ratingId}`,
    {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Basic ${btoa(`nnz:${token}`)}`,
      },
    })
    const _data = await data.json()
    if (_data.dynamic_fields.fields.length) {
      setPlug(_data.dynamic_fields.fields.map(it => ({...it, value: ""})))
      setTableMode(_data.dynamic_fields.table)
    }
    setLocalLoading(false)
  }
  
  
  const handleOutsideClick = (event) => {
    const path = event.path || (event.composedPath && event.composedPath());
    if (activeField.current && !path.includes(activeField.current)) {
      setActiveCell(null)
    }
  };

  const handleUploadFile = (e, rowInd) => {
    if (e.target.files) {
      const files = Object.values(e.target.files).map((item) => ({
        file: item,
        description: rowInd
      }));
      setAddFilesList((prev) => [...prev, ...files]);
    }
  };

  const handleDeleteFile = (id) => {
    const name = filesList.find(item => item.id==id).name
    setConfirmation({ id: id, name: name });
  };

  const deleteCurrentFile = (ind, description) => {
    const rowFiles = addFilesList.filter(file => file.description===description)
    rowFiles.splice(ind, 1);
    const otherRowsFiles = addFilesList.filter(file => file.description!==description)
    const arr = [...rowFiles, ...otherRowsFiles]
    setAddFilesList(arr);
  };

  const acceptConfirmation = (name) => {
    const arr = [...filesList];
    const index = filesList.findIndex(file => file.id == confirmation.id)
    arr.splice(index, 1);
    setRemoveFiles((prev) => [...prev, confirmation.id]);
    setFilesList(arr);
    setConfirmation(null);
  };

  const rejectConfirmation = () => {
    setConfirmation(null);
  };

  const handleInputChange = ({target: {name, value}}) => {
    const rowInd = activeCell?.rowInd || 0
    const index = data.dynamic_fields.fields[rowInd].map(it => it.name).indexOf(name)
    const newData = JSON.parse(JSON.stringify(data))
    newData.dynamic_fields.fields[rowInd][index].value = value
    setData(newData)
  }

  const addNewRow = () => {
    const newData = JSON.parse(JSON.stringify(data))
    newData.dynamic_fields.fields.push(plug)
    setData(newData)
  }

  const removeRow = (ind) => {
    const newData = JSON.parse(JSON.stringify(data))
    newData.dynamic_fields.fields.splice(ind, 1)
    setData(newData)
  }

  const switchComponent = (item, rowInd, tableMode) => {
    switch (item.type) {
        case "1": return (
          <StringField
            title={item.title}
            value={item.value}
            name={item.name}
            tableMode={tableMode}
            onChange={handleInputChange}
            disabled={!status.includes(data.access)}
            autoFocus
            />
        )
        case "2": return (
          <LinkField
            title={item.title}
            value={item.value}
            name={item.name}
            tableMode={tableMode}
            onChange={handleInputChange}
            // style={{width: "60%"}}
            disabled={!status.includes(data.access)}
            autoFocus
          />
        )
        case "3": return (
          <FilesField
            filesList={filesList}
            addFilesList={addFilesList}
            handleDeleteFile={handleDeleteFile}
            withoutInput={!status.includes(data.access)}
            tableMode={tableMode}
            handleUploadFile={handleUploadFile}
            deleteCurrentFile={deleteCurrentFile}
            rowInd={rowInd}
            />
        )
        case "4": return (
          <DateField
            title={item.title}
            value={item.value}
            tableMode={tableMode}
            name={item.name}
            disabled={!status.includes(data.access)}
            onChange={handleInputChange}
            className={styles.datePickerField}
            autoFocus
          />
        )
        case "5": return (
          <IntegerField
            title={item.title}
            value={item.value}
            name={item.name}
            disabled={!status.includes(data.access)}
            tableMode={tableMode}
            type="number"
            min="0"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={handleInputChange}
            style={{width: "50px"}}
            autoFocus
          />
        )
        case "6": return (
          <InternalLink
            tableMode={tableMode}
            title={item.title}
          />
        )
        default: return
      }
  }

  const switchCell = (item, rowInd) => {
    switch (item.type) {
      case "1": return (
        <div>{item.value}</div>
      )
      case "2": return (
        <div onClick={e => e.stopPropagation()}>
          <a
            href={item.value}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.value}
          </a>
        </div>
      )
      case "3": return (
        <FilesField
          filesList={filesList}
          addFilesList={addFilesList}
          handleDeleteFile={handleDeleteFile}
          rowInd={rowInd}
          disabled={!status.includes(data.access)}
          tableMode={true}
          handleUploadFile={handleUploadFile}
          deleteCurrentFile={deleteCurrentFile}
          withoutInput
        />
      )
      case "4": return (
        <div>{item.value}</div>
      )
      case "5": return (
        <div>{item.value}</div>
      )
      case "6": return (
        <InternalLink
          tableMode={true}
          title={item.title}
        />
      )
      default: return
    }
  }

  return (
    <div style={{minHeight: "300px"}}>
      {loading || localLoading
        ? <Loader top="40%" left="47.5%"/>
        :
        <>
        {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          confirmation={confirmation.name}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={rejectConfirmation}
        />
        )}
      {data && (
        <div className={styles.paramsWrapper}>
          <div>
            {data.parameter_name}
          </div>
          <CustomCheckbox
            label="Подтверждено"
            value={data.is_confirmed}
            onChange={() =>
              setData((prev) => ({ ...prev, is_confirmed: !prev.is_confirmed }))
            }
            disabled={status.length<2}
          />
          <div className={styles.description}>
            <div className={styles.title}>Комментарий АГШ:</div>
            <textarea
              disabled={status.length<2}
              value={data.confirmation_message}
              onChange={(e) => setData(prev => ({...prev, confirmation_message: e.target.value }))}
            />
          </div>
          {data.dynamic_fields
            && data.dynamic_fields.fields
            && !!data.dynamic_fields.fields.length
            && Array.isArray(data.dynamic_fields.fields[0])
            && plug
            && (data.dynamic_fields.table ?
            <table>
              <thead>
                <tr>
                  <th width="30px">№</th>
                  {plug.map(item =>
                  <th>
                    <div>
                      {item.title}
                    </div>
                  </th>
                  )}
                </tr>
              </thead>
              <tbody>
                  {data.dynamic_fields.fields.map((row, rowInd) =>
                    <tr key={rowInd}>
                      <th>{rowInd+1}</th>
                      {row.map((field, fieldInd) =>
                        <th
                          key={field.title}
                          ref={
                            activeCell
                            && rowInd===activeCell.rowInd
                            && fieldInd===activeCell.fieldInd
                            ? activeField : null
                          }
                          onClick={() => setActiveCell({rowInd, fieldInd})}
                        >
                          {activeCell
                            && haveEditRight
                            && rowInd===activeCell.rowInd
                            && fieldInd===activeCell.fieldInd
                            ? switchComponent(field, rowInd, true)
                            : switchCell(field, rowInd)
                          }
                        </th>
                      )}
                      {haveEditRight && <div onClick={() => removeRow(rowInd)}><IoMdRemove/></div>}
                    </tr>
                  )}
              </tbody>
              {haveEditRight &&
              <div onClick={addNewRow}>
                <IoMdAdd/>
              </div>}
            </table>
            : data.dynamic_fields.fields[0].map(item => switchComponent(item, 0)))
          }
        </div>
      )}
        </>
      }
    </div>
  );
}

const StringField = ({
  title,
  tableMode,
  ...props
}) => {
  return (
    <div className={styles.confirmField}>
      {!tableMode && <div className={styles.title}>{title}:</div>}
      <textarea
        {...props}
      />
    </div>
  );
};

const LinkField = ({
  title,
  tableMode,
  ...props
}) => {
  return (
    <div className={styles.confirmField}>
      {!tableMode && <div className={styles.title}>{title}:</div>}
      <input
        {...props}
      />
    </div>
  );
};

const FilesField = ({
  filesList,
  tableMode,
  addFilesList,
  disabled,
  rowInd,
  handleDeleteFile,
  handleUploadFile,
  deleteCurrentFile,
  withoutInput
}) => {
  

  return (
    <div
      className={styles.filesBlock}
      style={{width: "100%"}}
    >
      {!tableMode && <div className={styles.title}>Список файлов:</div>}
      <div className={styles.filesList}>
        {!filesList.length && !addFilesList.length && (
          <div className={styles.filesListEmpty}>
            <IoMdRemoveCircleOutline />— список файлов пуст
          </div>
        )}
        {filesList &&
          filesList.filter(it => it.description==rowInd).map((it, i) => {
            
            const fileClick = (e) => {
              e.preventDefault()
              e.stopPropagation()
              const link = document.createElement('a')
              link.setAttribute('href', `${BACKEND_DOMAIN || window.location.origin}/${it.path}/${it.name}`);
              link.setAttribute('target', '_blank');
              link.click()
            }
            
            return (
                <div>
                    <FileLabel
                      fileName={it.name}
                      onClick={fileClick}
                      imageMode={it.file_type.includes('image')}
                      fileLink={`${BACKEND_DOMAIN || window.location.origin}/${it.path}/${it.name}`}
                      onDelete={() => handleDeleteFile(it.id)}
                    />
                </div>
            );
          })}
        {!!addFilesList.length &&
          addFilesList.filter(it => it.description==rowInd).map((item, i) => {
            return (
              <FileLabel
                fileName={item.file.name}
                imageMode={item.file.type.includes('image')}
                fileLink={URL.createObjectURL(item.file)}
                onDelete={() => deleteCurrentFile(i, item.description)}
              />
            )
          })}
      </div>
      {!withoutInput &&
      <div className={styles.inputBlock}>
        <InputFile
          multiple
          title="Выберите файл для загрузки"
          accept="application/pdf, .png, .jpg, .jpeg"
          onChange={(e) => handleUploadFile(e, rowInd)}
        />
      </div>}
    </div>
  );
};

const DateField = ({
  title,
  value,
  tableMode,
  name,
  onChange,
  className
}) => {
  return (
    <div className={styles.confirmField}>
      {!tableMode && <div className={styles.title}>{title}:</div>}
      <DatePicker
        className={className}
        selected={value ? moment(value, "DD.MM.YYYY") : null}
        onChange={(date) => onChange({target: {name: name, value: date.format("DD.MM.YYYY")}})}
        dateFormat="DD.MM.YYYY"
        showYearDropdown
      />
    </div>
  );
};

const IntegerField = ({
  title,
  tableMode,
  ...props
}) => {
  return (
    <div className={styles.confirmField}>
      {!tableMode && <div className={styles.title}>{title}:</div>}
      <input
        {...props}
      />
    </div>
  );
};

const InternalLink = ({
  title,
  tableMode,
}) => {
  
  const [popUp, setPopUp] = useState(false)

  const popUpRef = useRef(null)

  useEffect(() => {
    document.body.addEventListener('click', handleOutsideClick);
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    }
  }, []);
  const handleOutsideClick = (event) => {
    const path = event.path || (event.composedPath && event.composedPath());
    if (popUpRef.current && !path.includes(popUpRef.current)) {
      setPopUp(false)
    }
  };

  const linkClick = (item) => {
    const link = document.createElement('a')
    link.setAttribute('href', item.link);
    link.setAttribute('target', '_blank');
    link.click()
  }

  const style = tableMode && {
    border: "none",
    background: "transparent"
  }

  return (
    <div className={styles.confirmField}>
      {!tableMode && <div className={styles.title}>{title}:</div>}
      <div
        onClick={() => setPopUp(true)}
        className={styles.linksWrap}
        style={style}
        ref={popUpRef}
      >
        {popUp &&
          <div
            className={styles.linksPopUp}
          >
              {directories.map(item =>
                <div onClick={() => linkClick(item)}>
                  {item.name}
                </div>
              )}
          </div>
        }
      </div>
    </div>
  );
};
