import { connect } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import {
  getTaskCount,
  getTaskObject,
  deleteTaskObject,
  editTaskObject,
  addTaskObject,
  uploadImage,
  deleteImage,
  getSteps,
  addTaskStep,
  deleteTaskStep,
  editTaskStep,
  getTaskProfiles,
  getTaskRealization,
  changeCompleteStatus,
} from "../../actions/task";
import { getAllEvents } from "../../actions/news";
import { getDepartments, getDepartmentsByRegion } from "../../actions/departments";
import styles from "./Tasks.module.css";
import { ButtonRed } from "../../assets/common/ButtonRed/ButtonRed";
import { Pagination } from "../../assets/common/Pagination/Pagination";
import TasksModal from "./TasksModal";
import Confirmation from "../../assets/common/Confirmation/Confirmation";
import { IoMdClose } from "react-icons/io";
import Select from "react-select";
import { BsSortDown, BsSortDownAlt } from "react-icons/bs";
import Loader from "../../components/Loader/Loader";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import { useLocation } from "react-router-dom";
import qs from 'qs'
import ItemsPerPage from "../../components/ItemsPerPage";
import { debounce } from "lodash";






function Tasks({
  getTaskCount,
  getTaskObject,
  deleteTaskObject,
  editTaskObject,
  uploadImage,
  deleteImage,
  pageCount,
  data,
  articles,
  addTaskObject,
  getSteps,
  getDepartments,
  stepsData,
  addTaskStep,
  stepsPageCount,
  getDepartmentsByRegion,
  deleteTaskStep,
  editTaskStep,
  itemsOnPage,
  getTaskProfiles,
  getTaskRealization,
  changeCompleteStatus,
  getAllEvents,
  allEvents,
  history,
  accessRights,
  steps_data_load,
  loadingTask
}) {
  const [numberPage, setNumberPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [filters, setFilters] = useState({
    events: [],
    calendarDED: false,
    search: ''
  });
  const [sort, setSort] = useState("");
  const location = useLocation()
  const searchParams = qs.parse(location.search.slice(1))

  const fromEvent = !!searchParams.eventId;
  const fromQuest = history.location.state?.quest;
  const fromQuestAddMode = history.location.state?.questId;
  const deleteRights = accessRights.ac_delete;

  useEffect(() => {
    fromQuestAddMode && openModal("ADD");
  }, []);

  useEffect(() => {
    if (!fromQuest) {
      getTaskCount(null, filters);
      !allEvents.length && getAllEvents();
      if (fromEvent) {
        const filtersObject = {...filters, events: [{ value: searchParams.eventId }]}
        getTaskObject(null, null, filtersObject, sort);
      } else {
        getTaskObject(numberPage + 1, null, filters, sort);
      }
    }
  }, [numberPage, itemsOnPage, filters, sort]);

  useEffect(() => {
    if (fromEvent && data) {
      const { taskId } = searchParams
      const item = data.find((it) => it.id === parseInt(taskId));
      openModal("EDIT", item);
    } else if (fromQuest) {
      const item = history.location.state;
      openModal("EDIT", item);
    }
  }, [data]);

  const openModal = (type, item) => {
    setSelectedItem(item);
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    if (fromQuest || fromQuestAddMode) {
      const questId = fromQuest || fromQuestAddMode;
      history.push({
        pathname: "/quests",
        state: {
          questId: questId,
        },
      });
    } else {
      setIsModalOpen(false);
      setSelectedItem(null);
    }
  };

  const handleDeleteDocument = (id) => {
    deleteTaskObject(id, numberPage + 1, null, filters);
  };

  const handleSortClick = () => {
    const values = ["", "asc", "desc"];
    const newValueIndex = (values.indexOf(sort) + 1) % 3;
    setSort(values[newValueIndex]);
  };

  const handleFiltersChange = (e) => {
    const newFiltersObject = {...filters}
    Array.isArray(e)
      ? newFiltersObject.events = e
      : newFiltersObject.calendarDED = !filters.calendarDED
    setFilters(newFiltersObject)
  }

  const handlePageClick = (data) => {
    setNumberPage(data.selected);
  };

  const acceptConfirmation = () => {
    handleDeleteDocument(confirmation.id);
    setConfirmation(null);
  };

  
  const handleSetSearch = debounce((e) => {
    setFilters(prev => ({...prev, search: e.target.value}))
  }, 1000)
  

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.title}>Задания</div>
        {loadingTask
          ? <Loader left="47%"/>
          : <div className={styles.newsList}>
              <div className={styles.newsListHeader}>
                {accessRights.ac_create && (
                  <ButtonRed onClick={() => openModal("ADD")}>Добавить</ButtonRed>
                )}
                <input
                  type="text"
                  placeholder="Поиск..."
                  className={styles.searchField}
                  onChange={handleSetSearch}
                />
              </div>
              
              {!fromEvent && (
                <>
                  <Select
                    placeholder="Выберите мероприятие..."
                    options={allEvents.map((i) => ({ label: i.title, value: i.id }))}
                    value={filters.events}
                    onChange={handleFiltersChange}
                    noOptionsMessage={() => "ничего не найдено"}
                    className={styles.filter}
                    name='events'
                    isMulti
                  />
                  <CustomCheckbox
                    value={filters.calendarDED}
                    onChange={handleFiltersChange}
                    name='calendarDED'
                    label='Календарь дней единых действий'
                  />
                </>
              )}
              <div className={styles.tableHeader}>
                <div className={styles.displayname}>Список заданий:</div>
              </div>
              <div className={styles.table}>
                {data?.map((a, ind) => (
                    <div key={ind} className={styles.tableRow}>
                      <div
                        className={styles.displayname}
                        onClick={() => openModal("EDIT", a)}
                      >
                        <span>{a.name}</span>
                      </div>
                      <div className={styles.actions}>
                        {deleteRights ? (
                          <IoMdClose
                            onClick={() =>
                              setConfirmation({ text: a.name, id: a.id })
                            }
                          />
                        ) : (
                          <div style={{ height: "1.5rem" }}></div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
              {!fromEvent && (
                <div className={styles.pagination}>
                  <Pagination
                    pageCount={pageCount}
                    numberPage={numberPage}
                    onPageChange={handlePageClick}
                  />
                </div>
              )}
              {!fromEvent && (
                <div className={styles.controls}>
                  <div
                    onClick={handleSortClick}
                    className={`${styles.sortBlock} ${
                      sort ? styles.sortActive : ""
                    }`}
                  >
                    {sort && sort === "desc" ? <BsSortDown /> : <BsSortDownAlt />}
                  </div>
                  <ItemsPerPage/>
                </div>
              )}
            </div>
          }
      </div>
      {confirmation && (
        <Confirmation
          confirmation={confirmation.text}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={() => setConfirmation(null)}
        />
      )}
      {isModalOpen && (
        <TasksModal
          open={isModalOpen}
          type={modalType}
          onClose={closeModal}
          selectedItem={selectedItem}
          editObject={editTaskObject}
          addObject={addTaskObject}
          numberPage={numberPage}
          uploadImage={uploadImage}
          deleteImage={deleteImage}
          articles={articles}
          getSteps={getSteps}
          stepsData={stepsData}
          getDepartmentsByRegion={getDepartmentsByRegion}
          filters={filters}
          getDepartments={getDepartments}
          addTaskStep={addTaskStep}
          stepsPageCount={stepsPageCount}
          deleteTaskStep={deleteTaskStep}
          editTaskStep={editTaskStep}
          getTaskProfiles={getTaskProfiles}
          changeCompleteStatus={changeCompleteStatus}
          getTaskRealization={getTaskRealization}
          fromQuest={fromQuest}
          fromQuestAddMode={fromQuestAddMode}
          accessRights={accessRights}
          steps_data_load={steps_data_load}
          loadingTask={loadingTask}
        />
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  pageCount: state.task.page_count,
  data: state.task.data,
  articles: state.task.articles,
  lastUpdate: state.schedule.lastUpdate,
  stepsData: state.task.steps_data,
  stepsPageCount: state.task.steps_page_count,
  allEvents: state.schedule.allEvents,
  itemsOnPage: state.auth.itemsOnPage,
  accessRights: state.auth.accessRights.find((i) => i.name === "task") || {},
  steps_data_load: state.task.steps_data_load,
  loadingTask: state.task.loading
});

export default connect(mapStateToProps, {
  getTaskCount,
  changeCompleteStatus,
  getTaskObject,
  deleteTaskObject,
  getTaskProfiles,
  getDepartmentsByRegion,
  editTaskObject,
  uploadImage,
  getDepartments,
  addTaskObject,
  deleteImage,
  getSteps,
  addTaskStep,
  deleteTaskStep,
  editTaskStep,
  getAllEvents,
  getTaskRealization,
})(Tasks);
