import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCalendarPlanEvent, getCalendarPlan } from '../../actions/chiefs_schedule'
import { Calendar, momentLocalizer } from 'react-big-calendar';
import styles from './styles.module.css'
import moment from 'moment';
import CalendarModal from './Modal';
import { BsTrashFill } from "react-icons/bs";
import { getRandomInt } from '../../utils/generateId';
import Loader from '../../components/Loader/Loader';


moment.locale('ru');
const localizer = momentLocalizer(moment);

const labels = {
  next:"След.",
  previous:"Пред.",
  today:"Сегодня",
  month:"Месяц",
  day:"День",
  showMore: (num) => `Еще ${num} событий`
}

export default function ChiefsSchedule () {
  
  const dispatch = useDispatch()
  const { data, loading } = useSelector(state => state.chiefs_schedule)

  const [events, setEvents] = useState([]) 
  const [modalOpen, setModalOpen] = useState(false) 
  const [selectedEvent, setSelectedEvent] = useState(null) 
  const [currentViewDate, setCurrentViewDate] = useState(null) 
  const [selectedDate, setSelectedDate] = useState('') 


  useEffect(() => {
    dispatch(getCalendarPlan())
  }, [])

  useEffect(() => {
    const events = data?.map((event) => {
      const { end_date, start_date, name, created, updated, ...restEvent } = event
      const start = new Date(moment(start_date).subtract(3, 'h'))
      const end = new Date(moment(end_date).subtract(3, 'h'))
      return { start: start, end: end, title: name, ...restEvent}}) || []
    setEvents(events)
  }, [data])

  const handleNavigate = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD')
    dispatch(getCalendarPlan(formattedDate))
    setCurrentViewDate(formattedDate)
  }

  const closeModal = () => {
    setModalOpen(false)
    setSelectedEvent(null)
  }

  const handleSelectEvent = ({id}) => {
    setModalOpen(true)
    setSelectedEvent(id)
  }
  
  const handleSelectSlot = (slotInfo) => {
    setSelectedDate(moment(slotInfo.end).toISOString())
    setModalOpen(true)
  }

  const eventPropGetter = ({ color, color_text }) => {
    return {
        style: {
          color: color_text,
          backgroundColor: color,
          outline: 'none'
        }
      }
  }

  const handleDeleteEvent = (eventId) => (e) => {
    e.stopPropagation()
    dispatch(deleteCalendarPlanEvent(eventId, currentViewDate))
  }

  const EventComponent = ({event, ...props}) => {
  
    const {title, id} = event
  
    return (
      <div className={styles.eventBlock}>
        <div>{title}</div>
        <BsTrashFill onClick={handleDeleteEvent(id)}/>
      </div>
    )
  }
  
  const HeaderCellContent = (props) => {
    const { date } = props;
    const dayOfWeek = date.getDay();
  
    const className =
        dayOfWeek === 0 || dayOfWeek === 6
            ? styles.day_weekend
            : styles.day_working;
    return <span className={className}>{props.label}</span>;
  };

  const dayPropGetter = () => ({
    style: {height: 'auto'}
  })
  
  return (
    <div className={styles.container}>
      <div className={styles.titleBlock}>
        <h2>Календарный план основных мероприятий</h2>
        {loading && <Loader top='1px' right='-50px' size={30} color='#6a1919'/>}
      </div>
      <div className={styles.calendarBlock}>
          

          <Calendar
            localizer={localizer}
            events={events}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            onNavigate={handleNavigate}
            components={{
              event: EventComponent,
              month: {
                header: HeaderCellContent,
                dateHeader: HeaderCellContent
              }
            }}
            eventPropGetter={eventPropGetter}
            dayPropGetter={dayPropGetter}
            messages={labels}
            views={['month', 'day']}
            defaultView="month"
            showMultiDayTimes
            selectable
          />
          <CalendarModal
            currentViewDate={currentViewDate}
            selectedDate={selectedDate}
            open={modalOpen}
            closeModal={closeModal}
            eventId={selectedEvent}
          />
      </div>
    </div>
  )
}