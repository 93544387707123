import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux';

export default function PrivateRoute ({ children, ...rest }) {
 
    const { token: isAuth } = useSelector(state => state.auth)

    return (
      <Route {...rest} render={() => isAuth ? children : <Redirect to='/' />} />
    );
  }